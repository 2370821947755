import "./App.css";
import logo from "./images/safio.svg";
import linkedin from "./images/linkedin.svg";
import mail from "./images/mail.svg";
import bookit from "./images/bookit.svg";
import cma from "./images/cma.svg";
import sensorio from "./images/sensorio.svg";
import thumb from "./images/thumb.svg";
import users from "./images/users.svg";
import heart from "./images/heart.svg";
import euro from "./images/euro.svg";
import header from "./images/header.svg";
import comments from "./images/comments.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function App() {
  const this_year = new Date().getFullYear();

  return (
    <Container className="text-center" fluid="lg">
      <Navbar collapseOnSelect expand="sm">
        <Navbar.Brand href="/">
          <img src={logo} alt="Safio, s.r.o." />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <Nav>
              <Nav.Link href="#about-us">O&nbsp;nás</Nav.Link>
              <Nav.Link href="#why-safio">Proč Safio?</Nav.Link>
              <Nav.Link href="#portfolio">Portfolio</Nav.Link>
              <Nav.Link href="#contact">Kontakt</Nav.Link>
            </Nav>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>

      <Row className="justify-content-center" id="about-us">
        <Col xs={12} sm={12} md={6}>
          <h1 className="text-left">PROMĚŇUJEME NÁPADY V&nbsp;REALITU</h1>
          <h3 className="text-left">
            Zaměřujeme se na problematická místa Vašeho podnikání
          </h3>
          <hr className="hr-header" />
          <p className="text-left">
            Pomůžeme Vám automatizovat Vaši práci a&nbsp;snížit stres
            z&nbsp;komplikovaných procesů. Budete mít více času na rozvoj Vašeho
            podnikání.
          </p>
        </Col>
        <Col className="d-none d-md-block" md={6}>
          <img src={header} alt="Safio, s.r.o." className="header-icon" />
        </Col>
      </Row>

      <Row className="justify-content-center" id="why-safio">
        <Col xs={12}>
          <h2>Proč s&nbsp;námi spolupracovat?</h2>
          <hr className="hr-heading" />
        </Col>
      </Row>
      <Row className="justify-content-center why-row">
        <Col xs={12} sm={4}>
          <img src={thumb} alt="Thumbs up icon" className="why-icon" />
          <h3>Individuální přístup</h3>
          <p>Přizpůsobíme se Vašim potřebám.</p>
        </Col>
        <Col xs={12} sm={4}>
          <img src={users} alt="Users icon" className="why-icon" />
          <h3>Stojíme za Vámi</h3>
          <p>
            Produktem to teprve začíná. Víme, že nás potřebujete a jsme tu pro
            Vás.
          </p>
        </Col>
        <Col xs={12} sm={4}>
          <img src={heart} alt="Heart icon" className="why-icon" />
          <h3>Rozumíme Vám</h3>
          <p>... a chceme vědět, co Vás trápí.</p>
        </Col>
      </Row>
      <Row className="justify-content-center why-row">
        <Col xs={12} sm={4}>
          <img src={euro} alt="Euro icon" className="why-icon" />
          <h3>Šetříme Váš čas a&nbsp;peníze</h3>
          <p>Zjednodušíme komplikované procesy, takže Vy stihnete více.</p>
        </Col>
        <Col xs={12} sm={4}>
          <img src={comments} alt="Comments icon" className="why-icon" />
          <h3>Mluvíme Vaší řečí</h3>
          <p>Vše Vám vysvětlíme tak, že tomu porozumíte.</p>
        </Col>
      </Row>

      <Row className="justify-content-center" id="portfolio">
        <Col xs={12}>
          <h2>Na čem pracujeme?</h2>
          <hr className="hr-heading" />
        </Col>
      </Row>
      <Row className="justify-content-center why-row">
        <Col xs={12} sm={4}>
          <a href="https://bookit.safio.cz" className="portfolio-box">
            <img src={bookit} alt="BookIt" className="portfolio-icon" />
            <h3>BookIt</h3>
            <p>Systém pro plánování školních událostí.</p>
          </a>
        </Col>
        <Col xs={12} sm={4}>
          <a href="#tbd" className="portfolio-box">
            <img src={cma} alt="CMA" className="portfolio-icon" />
            <h3>CMA</h3>
            <p>Aplikace pro analýzu trhu s&nbsp;bazarovými vozy.</p>
          </a>
        </Col>
        <Col xs={12} sm={4}>
          <a href="https://sensorio.safio.cz" className="portfolio-box">
            <img src={sensorio} alt="Sensorio" className="portfolio-icon" />
            <h3>Sensorio</h3>
            <p>Kompletní řešení pro sběr a&nbsp;analýzu sensorických dat.</p>
          </a>
        </Col>
      </Row>

      <Row className="justify-content-center" id="contact">
        <Col xs={12}>
          <h2>Kontaktujte nás</h2>
          <hr className="hr-heading" />
        </Col>
      </Row>
      <Row className="justify-content-center why-row">
        <Col xs={12}>
          <p>
            <strong>
              Chcete s námi spolupracovat?
              <br />
              Máte dobrý nápad, ale nevíte, jak ho zrealizovat?
            </strong>
          </p>
          <p>
            Zavolejte nám nebo nám pošlete e-mail a&nbsp;my se Vám ozveme co
            nejdříve!
          </p>
        </Col>
        <Col xs={12} sm={3}>
          <a href="https://www.linkedin.com/in/fialakarel/">
            <div>
              <img
                src={linkedin}
                alt="LinkedIn icon"
                className="contact-icon"
              />
              <h5>Karel Fiala</h5>
            </div>
          </a>
        </Col>
        <Col xs={12} sm={3}>
          <a href="mailto:info@safio.cz">
            <div>
              <img src={mail} alt="Mail icon" className="contact-icon" />
              <h5>info@safio.cz</h5>
            </div>
          </a>
        </Col>
        <Col xs={12} sm={3}>
          <a href="https://www.linkedin.com/in/tomassabata/">
            <div>
              <img
                src={linkedin}
                alt="LinkedIn icon"
                className="contact-icon"
              />
              <h5>Tomáš Šabata</h5>
            </div>
          </a>
        </Col>
      </Row>

      <footer className="justify-content-center">
        &copy; 2019-{this_year}, Safio, s.r.o.
        <p>
          <small>
            Poličanská 1487, Újezd nad Lesy, 190 16 Praha 9
            <br />
            IČO: 08250006, DIČ: CZ08250006
            <br />
            Spisová značka: C 315609 vedená u Městského soudu v Praze
            <br />
            Právnická osoba zapsaná v obchodním rejstříku vedeném v Praze od 12.
            června 2019
          </small>
        </p>
      </footer>
    </Container>
  );
}

export default App;
